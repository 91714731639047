
import 'moment';
import moment from 'moment-timezone';
import {computed, defineComponent, ref, watch} from 'vue';
import store from "@/store";

export default defineComponent({
  name: "DateTimeFormat",
  props: {
    date: {
      type: Number,
      required: false,
      default: new Date().getTime()
    },
    format: {
      required: false,
      default: 'MM/DD/YYYY'
    },
    time: {type: Boolean},
    clazz: {
      required: false,
      default: ''
    }
  },

  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const value = ref('');
    const buildValue = async function (date, format, showTime) {
      const timezone = await user?.value?.timezone ? user.value.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (date === undefined || date === '' || date === null) {
        value.value = ''
      } else {
        const fm = format !== undefined ? format : "MM/DD/YYY";
        const finalFormat = showTime ? 'hh:mm A ' + fm : fm;
        const mm = moment(date).tz(timezone);
        if (mm !== undefined) {
          value.value = mm.format(finalFormat);
        }
      }
    };
    watch(user, async (cb) => {
      await buildValue(props.date, cb.dateFormat, props.time);
    })
    watch(() => props.time, async (cb) => {
      await buildValue(props.date, user.value.dateFormat, cb);
    });
    watch(() => props.date, async (date) => {
      await buildValue(date, user.value.dateFormat, props.time);
    });

    buildValue(props.date, user.value.dateFormat, props.time);
    return {
      value,
      user
    }
  }
})
